import React from 'react';
import { compose } from 'recompose';
import { withShowHide } from '@sparefoot/react-core/decorators';
import { withFixedSegmentCategory } from '@sparefoot/react-core/segment';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Container } from 'components/core/Page';
import { Menu } from 'components/brand/Menu';
import { Link } from 'components/core/Link';
import { ClickToCall } from 'components/facility/ClickToCall';
import Logo from 'components/svg/Logo';
import Dropdown from 'components/svg/Dropdown';
import { phonePropType } from 'config/propTypes';

import './Header.scss';
import { Checkbox } from '@sparefoot/react-core';

export const enhance = compose(
	withFixedSegmentCategory('header interactions'),
	withShowHide('navigation'),
	withShowHide('owners'),
);

export function Header({
	navigationShowing,
	phone,
	getTollfreePhoneNumber,
	onToggleOwners,
	ownersShowing,
	onSetOwners,
}) {
	return (
		<header className="site-header">
			<nav className="navbar">
				<Container>
					<Link
						to="/"
						className="logo-link"
						segmentLabel="logo"
					>
						<Logo />
					</Link>
					<div className="nav-right">
						<div className="menu-item">
							{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
							<label
								className={classnames(
									'menu-link dropdown-toggle owners-dropdown-menu ss-link',
								)}
								htmlFor="owners-checkbox"
							>
								<Checkbox
									onChange={onToggleOwners}
									key="Owners Dropdown"
									id="owners-checkbox"
									name="owners-checkbox"
									segmentLabel="Owners dropdown"
									className="visually-hidden"
									checked={ownersShowing}
								/>
								Owners <Dropdown />
							</label>
							<div>
								<ul
									className={classnames(
										'dropdown-menu',
										ownersShowing && 'showing',
									)}
								>
									<li className="menu-item">
										<Link
											className="menu-link"
											href="https://www.storable.com/products/marketplace/"
											rel="noopener noreferrer"
											segmentLabel="add your facility"
										>
											Add Your Facility
										</Link>
									</li>
									<li className="menu-item">
										<Link
											className="menu-link"
											href="https://argus-selfstorage.com/"
											rel="noopener noreferrer"
											segmentLabel="owners dropdown-header-buy & sell facilities"
										>
											Buy & Sell Facilities
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="menu-item">
							<Link
								className="menu-link"
								href="https://www.storable.com/products/marketplace/"
								rel="noopener noreferrer"
								segmentLabel="add your facility"
							>
								Add Your Facility
							</Link>
						</div>
						<ClickToCall
							phone={phone}
							onFetchNumber={getTollfreePhoneNumber}
							phoneIcon={false}
						/>

						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label
							className={classnames('nav-button', {
								'open-menu': navigationShowing,
							})}
							htmlFor="menu-toggle-button"
						>
							<span className="icon-bars">
								<span className="visually-hidden">
									Open Navigation
								</span>
							</span>

							<Checkbox
								key="menu-toggle-button"
								id="menu-toggle-button"
								name="menu-toggle-button"
								segmentLabel="toggle mobile navigation"
								className="visually-hidden"
							/>
						</label>
					</div>
					<Menu
						ownersShowing={ownersShowing}
						onToggleOwners={onToggleOwners}
						onSetOwners={onSetOwners}
					/>
				</Container>
			</nav>
		</header>
	);
}

Header.propTypes = {
	navigationShowing: PropTypes.bool.isRequired,
	phone: phonePropType,
	getTollfreePhoneNumber: PropTypes.func.isRequired,
	onToggleOwners: PropTypes.func.isRequired,
	ownersShowing: PropTypes.bool.isRequired,
	onSetOwners: PropTypes.func.isRequired,
};

export default React.memo(enhance(Header));
