import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withFixedSegmentCategory } from '@sparefoot/react-core/segment';
import homepageSelector from 'store/selectors/homepage';
import { phonePropType } from 'config/propTypes';
import { Row, Column, Container, Group } from 'components/core/Page';
import { Paragraph } from 'components/core/Typography';
import { Link } from 'components/core/Link';
import { ClickToCall } from 'components/facility/ClickToCall';
import { StatesList } from 'components/brand/StatesList';

import Logo from 'components/svg/Logo';
import Facebook from 'components/svg/Facebook';
import Twitter from 'components/svg/Twitter';

import FooterLinks from './FooterLinks';

import './Footer.scss';

export const enhance = compose(
	connect(homepageSelector),
	withFixedSegmentCategory('footer interactions'),
);

const storageLinks = [
	{ href: '/storage-units-near-me', label: 'Storage Near You' },
	{
		href: '/storage-units-with-climate-control',
		label: 'Climate Controlled Storage',
	},
	{ href: '/vehicle-storage', label: 'Vehicle Storage' },
	{ href: '/what-to-expect', label: 'Size Guide' },
	{
		href: 'https://moving.selfstorage.com/',
		target: '_blank',
		label: 'Moving Blog',
		rel: 'noopener noreferrer',
	},
	{
		href: 'https://www.storable.com/products/marketplace/',
		target: '_blank',
		label: 'Add Your Facility',
	},
	{ href: '/contact-us', label: 'Contact Us' },
];

const topCitiesLinks = [
	{ href: '/self-storage/new-mexico/albuquerque/', label: 'Albuquerque' },
	{ href: '/self-storage/texas/austin/', label: 'Austin' },
	{ href: '/self-storage/massachusetts/boston/', label: 'Boston' },
	{ href: '/self-storage/new-york/brooklyn/', label: 'Brooklyn' },
	{ href: '/self-storage/north-carolina/charlotte/', label: 'Charlotte' },
	{ href: '/self-storage/illinois/chicago/', label: 'Chicago' },
	{
		href: '/self-storage/colorado/colorado-springs/',
		label: 'Colorado Springs',
	},
	{ href: '/self-storage/texas/dallas/', label: 'Dallas' },
	{ href: '/self-storage/colorado/denver/', label: 'Denver' },
	{ href: '/self-storage/texas/fort-worth/', label: 'Fort-Worth' },
	{ href: '/self-storage/texas/houston/', label: 'Houston' },
	{ href: '/self-storage/indiana/indianapolis/', label: 'Indianapolis' },
	{ href: '/self-storage/florida/jacksonville/', label: 'Jacksonville' },
	{ href: '/self-storage/nevada/las-vegas/', label: 'Las Vegas' },
	{ href: '/self-storage/california/los-angeles/', label: 'Los Angeles' },
	{ href: '/self-storage/florida/miami/', label: 'Miami' },
	{ href: '/self-storage/tennessee/nashville/', label: 'Nashville' },
	{ href: '/self-storage/new-york/new-york/', label: 'New York' },
	{ href: '/self-storage/pennsylvania/philadelphia/', label: 'Philadelphia' },
	{ href: '/self-storage/arizona/phoenix/', label: 'Phoenix' },
	{ href: '/self-storage/texas/san-antonio/', label: 'San Antonio' },
	{ href: '/self-storage/california/san-diego/', label: 'San Diego' },
	{ href: '/self-storage/california/san-jose/', label: 'San Jose' },
	{ href: '/self-storage/florida/tampa/', label: 'Tampa' },
	{ href: '/self-storage/arizona/tucson/', label: 'Tucson' },
];

const selfStorageAffiliates = [
	{
		href: 'https://www.sparefoot.com/',
		label: 'Sparefoot',
		target: '_blank',
	},
	{ href: 'https://www.storage.com/', label: 'Storage', target: '_blank' },
	{ href: 'https://www.storable.com/', label: 'Storable', target: '_blank' },
];

export function Footer({ year, phone, getTollfreePhoneNumber, baseUrl }) {
	return (
		<footer className="site-footer">
			<Container>
				<Row className="top-cities-row">
					<Column
						large={8}
						className="top-cities-col"
					>
						<Group>
							<Paragraph>
								Top Cities for Self Storage in the Country
							</Paragraph>
							<FooterLinks
								className="top-cities-links"
								links={topCitiesLinks}
								segmentPrefix="top cities Links"
								baseUrl={baseUrl}
							/>
						</Group>
					</Column>
					<Column large={4}>
						<Group>
							<StatesList />
						</Group>
					</Column>
				</Row>
				<Row>
					<Column large={9}>
						<Group>
							<Link
								to="/"
								segmentLabel="logo"
							>
								<Logo />
							</Link>
							<div>
								<Paragraph>
									<Link
										to="/"
										segmentLabel="self storage"
									>
										SelfStorage.com
									</Link>{' '}
									is one of the original self-storage
									comparison shopping sites, launched to help
									customers find the best deal on storage
									units.
								</Paragraph>
								<Paragraph>
									Featuring more than 20,000 storage
									facilities nationwide, you can find a cheap
									storage unit near you by searching on{' '}
									<Link
										to="/"
										segmentLabel="self storage"
									>
										SelfStorage.com
									</Link>
									. Compare prices and unit sizes, and reserve
									your storage unit for free in minutes. No
									commitment and no credit card required!
								</Paragraph>
							</div>
						</Group>
					</Column>
					<Column large={3}>
						<Group>
							<Paragraph>Storage Links</Paragraph>
							<FooterLinks
								links={storageLinks}
								segmentPrefix="facility types Links"
								className="storage-links"
								baseUrl={baseUrl}
							/>
						</Group>
						<Group>
							<Paragraph>Storable Brands</Paragraph>
							<FooterLinks
								links={selfStorageAffiliates}
								segmentPrefix="affiliates Links"
								className="storage-links"
							/>
						</Group>
					</Column>
				</Row>
				<Group>
					<div className="social-media-links">
						<Link
							href="//www.facebook.com/SelfStorageInc"
							className="social-icon"
							segmentLabel="facebook"
						>
							<Facebook />
						</Link>
						<Link
							href="//twitter.com/selfstorageinc"
							className="social-icon"
							segmentLabel="twitter"
						>
							<Twitter />
						</Link>
						<ClickToCall
							phone={phone}
							onFetchNumber={getTollfreePhoneNumber}
							phoneIcon={false}
						/>
					</div>
					<div className="footer-copyright">
						<Paragraph>
							&copy; {year} SelfStorage.com All rights reserved.
						</Paragraph>
						<Link
							href="/legal/consumer"
							rel="nofollow"
							segmentLabel="terms of service"
						>
							Terms of Service and Privacy Policy
						</Link>
					</div>
				</Group>
			</Container>
		</footer>
	);
}

Footer.propTypes = {
	year: PropTypes.number,
	phone: phonePropType,
	getTollfreePhoneNumber: PropTypes.func.isRequired,
	baseUrl: PropTypes.string,
};

Footer.defaultProps = {
	year: new Date().getFullYear(),
};

export default React.memo(enhance(Footer));
