import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withSegmentContext } from '@sparefoot/react-core/segment';

import { Link } from 'components/core/Link';

export const enhance = withSegmentContext;

export function FooterLinks({ links, className, baseUrl = '' }) {
	return (
		<ul className={classnames('footer-links', className)}>
			{links.map((link) => (
				<li
					key={link.href || link.to}
					className="footer-link"
				>
					<Link
						segmentLabel={link.label}
						href={
							link.href?.includes('https://')
								? `${link.href}`
								: `${baseUrl}${link.href}`
						}
						target={link.target}
						rel={link.rel}
					>
						{link.label}
					</Link>
				</li>
			))}
		</ul>
	);
}

FooterLinks.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			href: PropTypes.string,
			to: PropTypes.string,
		}),
	),
	className: PropTypes.string,
	baseUrl: PropTypes.string,
};

export default enhance(FooterLinks);
