import { createSelector } from 'reselect';
import { get } from 'lodash';

import { getIndexViewPixel } from '@sparefoot/react-core/utils/tracking';

export default createSelector(
	(state) => state.pages.home,
	(state) => state.app.baseUrl,
	(home, baseUrl) => ({
		meta: {
			...get(home, 'meta', {}),
			canonical: baseUrl + home?.meta?.canonical,
		},
		featuredCities: get(home, 'featuredCities', []),
		trackingPixel: getIndexViewPixel(),
		baseUrl: baseUrl,
	}),
);
